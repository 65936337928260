
import { defineComponent } from "vue";
import { data } from "@/shared/products";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";

export default defineComponent({
  components: { AppLink },
  emits: ["linkSpecClick"],
  setup() {
    const { spec } = data;
    return {
      spec
    };
  }
});
