<template>
  <svg
    width="306"
    height="96"
    viewBox="0 0 306 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M282.001 73H221.001C216.583 73 213.001 69.4183 213.001 65V9C213.001 4.58172 216.583 1 221.001 1H297.001C301.419 1 305.001 4.58172 305.001 9V65C305.001 69.4183 301.419 73 297.001 73H291.201"
      stroke="#333333"
      stroke-width="1.8"
      stroke-linecap="round"
    />
    <path
      d="M213 90.1001L305 90.1001"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M44 82H68"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M286.422 95C289.183 95 291.422 92.7614 291.422 90C291.422 87.2386 289.183 85 286.422 85C283.66 85 281.422 87.2386 281.422 90C281.422 92.7614 283.66 95 286.422 95Z"
      fill="white"
      stroke="#333333"
      stroke-width="1.8"
    />
    <path
      d="M127 21L127 75"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M149 21L149 75"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M171 21L171 75"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M120.001 60C120.001 63.866 123.135 67 127.001 67C130.867 67 134.001 63.866 134.001 60C134.001 56.134 130.867 53 127.001 53C123.135 53 120.001 56.134 120.001 60Z"
      fill="#4384FF"
    />
    <path
      d="M142.001 36C142.001 39.866 145.135 43 149.001 43C152.867 43 156.001 39.866 156.001 36C156.001 32.134 152.867 29 149.001 29C145.135 29 142.001 32.134 142.001 36Z"
      fill="#4384FF"
    />
    <path
      d="M164.001 60C164.001 63.866 167.135 67 171.001 67C174.867 67 178.001 63.866 178.001 60C178.001 56.134 174.867 53 171.001 53C167.135 53 164.001 56.134 164.001 60Z"
      fill="#4384FF"
    />
    <path
      d="M27 57.2783C27 67.2984 27 79.2782 27 87.0068C27 91.4251 30.5817 95 35 95H77C81.4183 95 85 91.4183 85 87V9C85 4.58172 81.4183 1 77 1H35C30.5817 1 27 4.58172 27 9V46.6311"
      stroke="#333333"
      stroke-width="1.8"
      stroke-linecap="round"
    />
    <path
      d="M195 55.0753V89C195 92.3137 192.314 95 189 95H109C105.686 95 103 92.3137 103 89V7C103 3.68629 105.686 1 109 1H189C192.314 1 195 3.68629 195 7V56.6699"
      stroke="#333333"
      stroke-width="1.8"
      stroke-linecap="round"
    />
    <circle
      cx="259"
      cy="23.5"
      r="9"
      fill="#FED800"
      stroke="#333333"
      stroke-width="1.8"
    />
    <path
      d="M279 58C279 46.9543 270.046 38 259 38C247.954 38 239 46.9543 239 58"
      stroke="#333333"
      stroke-width="1.8"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 14C4.58172 14 1 17.5817 1 22V43C1 47.4183 4.58172 51 9 51H36.8744L38.4938 52.6194L43.1883 57.3139C43.8183 57.9438 44.8954 57.4977 44.8954 56.6068V51H48C52.4183 51 56 47.4183 56 43V22C56 17.5817 52.4183 14 48 14H9Z"
      fill="#FED800"
    />
    <path
      d="M36.8744 51L37.5108 50.3636L37.2472 50.1H36.8744V51ZM43.1883 57.3139L43.8247 56.6775H43.8247L43.1883 57.3139ZM44.8954 51V50.1H43.9954V51H44.8954ZM1.9 22C1.9 18.0788 5.07878 14.9 9 14.9V13.1C4.08467 13.1 0.1 17.0847 0.1 22H1.9ZM1.9 43V22H0.1V43H1.9ZM9 50.1C5.07878 50.1 1.9 46.9212 1.9 43H0.1C0.1 47.9153 4.08466 51.9 9 51.9V50.1ZM36.8744 50.1H9V51.9H36.8744V50.1ZM39.1302 51.983L37.5108 50.3636L36.238 51.6364L37.8574 53.2558L39.1302 51.983ZM43.8247 56.6775L39.1302 51.983L37.8574 53.2558L42.5519 57.9503L43.8247 56.6775ZM43.9954 56.6068C43.9954 56.6417 43.9867 56.6559 43.9816 56.6633C43.9735 56.6747 43.9579 56.6891 43.9337 56.6992C43.9095 56.7092 43.8882 56.71 43.8744 56.7076C43.8656 56.7061 43.8494 56.7022 43.8247 56.6775L42.5519 57.9503C43.7488 59.1472 45.7954 58.2995 45.7954 56.6068H43.9954ZM43.9954 51V56.6068H45.7954V51H43.9954ZM48 50.1H44.8954V51.9H48V50.1ZM55.1 43C55.1 46.9212 51.9212 50.1 48 50.1V51.9C52.9153 51.9 56.9 47.9153 56.9 43H55.1ZM55.1 22V43H56.9V22H55.1ZM48 14.9C51.9212 14.9 55.1 18.0788 55.1 22H56.9C56.9 17.0847 52.9153 13.1 48 13.1V14.9ZM9 14.9H48V13.1H9V14.9Z"
      fill="#333333"
    />
    <rect
      x="11.8877"
      y="30.6709"
      width="3.65806"
      height="3.65806"
      rx="1.82903"
      fill="#333333"
    />
    <rect
      x="26"
      y="30.6709"
      width="3.65806"
      height="3.65806"
      rx="1.82903"
      fill="#333333"
    />
    <rect
      x="40"
      y="30.6709"
      width="3.65806"
      height="3.65806"
      rx="1.82903"
      fill="#333333"
    />
  </svg>
</template>
