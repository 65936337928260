
import { computed, defineComponent } from "vue";
import { trackerSetClick } from "@/utils/vertebraSdk";
import { useRoute } from "vue-router";
import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";
import { ROUTER_PATH as list } from "@/shared/routerPath";
import { data } from "@/shared/products";
import ProductsIcon from "@/components/atoms/icon/ProductsIcon/ProductsIcon.vue";
import SubIntroContent from "@/components/templates/SubIntroContent/SubIntroContent.vue";
import SubBannerSignup from "@/components/templates/SubBannerSignup/SubBannerSignup.vue";
import ProductsPrimaryBox from "@/components/templates/ProductsPrimaryBox/ProductsPrimaryBox.vue";
import ProductsFeatures from "@/components/templates/ProductsFeatures/ProductsFeatures.vue";
import SiteShortcut from "@/components/templates/SiteShortcut/SiteShortcut.vue";
export default defineComponent({
  components: {
    ProductsIcon,
    SubIntroContent,
    SubBannerSignup,
    ProductsPrimaryBox,
    ProductsFeatures,
    SiteShortcut
  },
  setup() {
    useHead({ title: data.sitemapTitle });
    const trackEventConsultingLivecall = () => {
      gtagEvents("call-문의하기-footer");
      trackerSetClick("ask-call-footer");
    };
    const trackEventConsultingLivecast = () => {
      gtagEvents("cast-문의하기-footer");
      trackerSetClick("ask-cast-footer");
    };
    const trackEventConsultingLiveconference = () => {
      gtagEvents("conference-문의하기-footer");
      trackerSetClick("ask-conference-footer");
    };
    const route = useRoute();
    const pathName = computed(() => route.name);
    const trackEventsInit = () => {
      switch (pathName.value) {
        case "ProductLiveCall":
          trackEventConsultingLivecall();
          break;
        case "ProductLiveCast":
          trackEventConsultingLivecast();
          break;
        case "ProductLiveConference":
          trackEventConsultingLiveconference();
          break;
        default:
          break;
      }
    };
    const items = list[0].children;
    const { title, description, livecall } = data;

    return {
      items,
      title,
      description,
      livecall,
      pathName,
      trackEventsInit
    };
  }
});
