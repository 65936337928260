import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contents-wrap" }
const _hoisted_2 = { class: "contents-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductsBoxContainer = _resolveComponent("ProductsBoxContainer")!
  const _component_ProductsService = _resolveComponent("ProductsService")!
  const _component_ProductsSpec = _resolveComponent("ProductsSpec")!
  const _component_ProductsBannerImage = _resolveComponent("ProductsBannerImage")!
  const _component_ProductsSubject = _resolveComponent("ProductsSubject")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ProductsBoxContainer, {
        title: _ctx.livecall.platform.title,
        description: _ctx.livecall.platform.description,
        productList: _ctx.livecall.platform.list,
        dataName: _ctx.livecall.name
      }, null, 8, ["title", "description", "productList", "dataName"]),
      _createVNode(_component_ProductsService, {
        serviceList: _ctx.livecall.service.list,
        dataName: _ctx.livecall.name
      }, null, 8, ["serviceList", "dataName"]),
      _createVNode(_component_ProductsSpec, { onLinkSpecClick: _ctx.trackEventSpecLivecall }, null, 8, ["onLinkSpecClick"])
    ]),
    _createVNode(_component_ProductsBannerImage, {
      imgName: _ctx.livecall.name
    }, null, 8, ["imgName"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProductsSubject, {
        title: _ctx.livecall.subject.title,
        subjectList: _ctx.livecall.subject.list,
        dataName: _ctx.livecall.name
      }, null, 8, ["title", "subjectList", "dataName"])
    ])
  ], 64))
}