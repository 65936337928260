import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2590c5de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subject-wrap" }
const _hoisted_2 = { class: "main-content-title" }
const _hoisted_3 = { class: "itemset" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjectList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "itemset-inner",
          key: index
        }, [
          _createElementVNode("img", {
            class: "itemset-img",
            src: 
            `${_ctx.imgSrc}/img-products-subject-${_ctx.dataName}${index + 1}.png?1.0`
          ,
            alt: ""
          }, null, 8, _hoisted_4),
          _createElementVNode("strong", {
            class: "itemset-title",
            innerHTML: item.title
          }, null, 8, _hoisted_5),
          _createElementVNode("p", {
            class: "itemset-description",
            innerHTML: item.description
          }, null, 8, _hoisted_6)
        ]))
      }), 128))
    ])
  ]))
}