
import { defineComponent, onMounted } from "vue";
import { trackerSetAction, trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";
import { data } from "@/shared/products";
import ProductsBoxContainer from "@/components/templates/ProductsBoxContainer/ProductsBoxContainer.vue";
import ProductsService from "@/components/templates/ProductsService/ProductsService.vue";
import ProductsSpec from "@/components/templates/ProductsSpec/ProductsSpec.vue";
import ProductsBannerImage from "@/components/templates/ProductsBannerImage/ProductsBannerImage.vue";
import ProductsSubject from "@/components/templates/ProductsSubject/ProductsSubject.vue";
export default defineComponent({
  name: "ProductLiveCall",
  components: {
    ProductsBoxContainer,
    ProductsService,
    ProductsSpec,
    ProductsBannerImage,
    ProductsSubject
  },
  setup() {
    useHead({
      link: [
        {
          rel: "canonical",
          href: `${process.env.VUE_APP_BASE_URL}/products/livecall`
        }
      ]
    });
    const trackEventSpecLivecall = () => {
      gtagEvents("call-상세스펙");
      trackerSetClick("go-call-spec");
    };
    const { livecall } = data;
    onMounted(() => {
      trackerSetAction("상품 Call 페이지");
    });
    return {
      livecall,
      trackEventSpecLivecall
    };
  }
});
